import styled from 'styled-components';

import { Section } from 'components/section';
import { Icon } from 'components/icons';

import { Theme, FONTS, COLORS } from 'theme';

const { Button, Title } = Theme;

export const HomeStyle = {
  Wrapper: styled(Section)`
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: ${FONTS.regular};
  `,

  GameCreateBlock: styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    margin: 0 40px 20px 40px;
    align-self: center;
  `,

  EmptyGameBlock: styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
  `,
  Title: styled(Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
  `,
  InfoTitle: styled.p`
    font-family: ${FONTS.semiBold};
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 0;
  `,
  Tip: styled.p`
    font-family: ${FONTS.regular};
    margin-top: 16px;
  `,
  Icon: styled(Icon)`
    width: 476px;
  `,

  Spinner: styled.div`
    position: absolute;
    align-self: center;
    top: calc(50% - 50px);
  `,

  Button: styled(Button)`
    margin-top: 0;

    :disabled {
      background-color: ${COLORS.gray};
      cursor: not-allowed;
    }
  `,
};
