

import { COLORS } from 'theme';

import { HomeStyle } from '../../home.style';
import { CreateBlock } from '../create-block';
import { useReduxSelector } from 'services/hooks';
import { AdminGame } from '../admin-game';

import { AdminGamesStyles as Styles } from './admin-games.styles';
import { sortGamesByDateAndStatus } from 'services/utils';

export const AdminGames = () => {
  const games = useReduxSelector((redux) => redux.main.games);

  const sorted = sortGamesByDateAndStatus(games);

  const RenderGames = sorted.map((game) => (
    <AdminGame
      key={game.config.gameCode}
      gameCode={game.config.gameCode}
      status={game.status}
      currentRound={game.rounds?.length}
      title={game.config.gameTitle}
      description={game.config.description}
    />
  ));

  return (
    <HomeStyle.Wrapper backgroundColor={COLORS.white}>
      <HomeStyle.GameCreateBlock>
        <CreateBlock />

        <HomeStyle.Title>Your games</HomeStyle.Title>

        <Styles.GamesWrapper>{RenderGames}</Styles.GamesWrapper>
      </HomeStyle.GameCreateBlock>
    </HomeStyle.Wrapper>
  );
};
