import styled from 'styled-components';

import { Grid } from 'components/UI';

export const AdminGamesStyles = {
  GamesWrapper: styled(Grid)`
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    column-gap: 32px;
    row-gap: 24px;
  `,
};
