import styled, { css } from 'styled-components';

import { FONT_SIZE } from './font-size';
import { FONTS } from './fonts';

const NormalizeCss = css`
  margin: 0;
  padding: 0;
`;

const Regular = css`
  font-family: ${FONTS.regular};
  font-size: ${FONT_SIZE.label};
  line-height: normal;
`;
const QuicksandRegular14 = css`
  font-family: ${FONTS.regular};
  font-size: ${FONT_SIZE.small};
  line-height: 20px;
`;
const QuicksandRegular16 = css`
  font-family: ${FONTS.regular};
  font-size: ${FONT_SIZE.px16};
  line-height: 20px;
`;
const QuicksandRegular18 = css`
  font-family: ${FONTS.regular};
  font-size: ${FONT_SIZE[18]};
  line-height: 22.5px;
`;
const QuicksandMedium16 = css`
  font-family: ${FONTS.medium};
  font-size: ${FONT_SIZE.px16};
  line-height: 20px;
`;
const QuicksandBold14 = css`
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE.small};
  line-height: 20px;
`;

const QuicksandBold16 = css`
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE.label};
  line-height: 20px;
`;

const QuicksandBold18 = css`
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE.desktopMiddle};
  line-height: 22.5px;
`;

const QuicksandBold24 = css`
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE.desktopMiddle};
  line-height: normal;
`;

const QuicksandBold32 = css`
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE[32]};
  line-height: 40px;
`;

const QuicksandBold80 = css`
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE[80]};
  line-height: normal;
`;

const QuicksandBoldLeft18 = css`
  text-align: left;
  font-family: ${FONTS.quicksandBold};
  font-size: ${FONT_SIZE.desktopMiddle};
  line-height: 22.5px;
`;

const RalewaySemiBold18 = css`
  font-family: ${FONTS.semiBold};
  font-size: ${FONT_SIZE[18]};
  line-height: 24px;
`;

const RalewaySemiBold20 = css`
  font-family: ${FONTS.semiBold};
  font-size: ${FONT_SIZE.regular};
  line-height: 24px;
`;

const RalewayBold13 = css`
  font-family: ${FONTS.bold};
  font-size: ${FONT_SIZE[13]};
  line-height: normal;
`;

const RalewayBold24 = css`
  font-family: ${FONTS.bold};
  font-size: ${FONT_SIZE[24]};
  line-height: 1;
`;

const H3QuicksandBoldLeft18 = styled.h3`
  ${NormalizeCss};
  ${QuicksandBoldLeft18};
`;

export const typographyCss = {
  Regular,
  QuicksandRegular14,
  QuicksandRegular16,
  QuicksandRegular18,
  QuicksandMedium16,
  QuicksandBold14,
  QuicksandBold16,
  QuicksandBold18,
  QuicksandBold24,
  QuicksandBold32,
  QuicksandBold80,
  QuicksandBoldLeft18,
  RalewaySemiBold18,
  RalewaySemiBold20,
  RalewayBold13,
  RalewayBold24,
};

export const Typography = {
  H3QuicksandBoldLeft18,
};
